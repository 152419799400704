<script lang="ts">
	import { _ } from 'svelte-i18n';

	// const currentUser = getCurrentUserContext();

	// export let langLocale: any;

	// $: $locale = langLocale;
</script>

<div class="bg-white h-full flex justify-center flex-col">
	<!-- <MolLandingHeader />
	<OrgHomeCta /> -->

	<div class="w-full flex flex-col items-center -translate-y-5">
		<div class="w-32 flex justify-center mb-6 items-center shrink-0">
			<img alt="logo" src="/images/logos/fixee_logo_transparent.png" />
		</div>
		<div class="max-w-md flex flex-col gap-6 items-center mx-5">
			<!-- <AtomText type="h1" color="primary-theme-color" class="!text-4xl text-center"
				>{$_('landing.beta.title')}</AtomText
			>
			<AtomText type="subtitle" color="secondary-darker" class="text-center"
				>{$_('landing.beta.subtitle')}</AtomText
			> -->

			<!-- VERY IMPORTANT to keep the AtomButton here for now, without this if we run "rm -rf .svelte-kit ; yarn dev"
				 When we change this main page design but we will have to check that the page can be opened without issue after running the command above.
				 The bug can be an infinite load on a blank page
			-->
			<!-- VERY IMPORTANT to keep the AtomButton here for now, without this if we run "rm -rf .svelte-kit ; yarn dev"
				 When we change this main page design but we will have to check that the page can be opened without issue after running the command above.
				 The bug can be an infinite load on a blank page
			-->
			<!-- VERY IMPORTANT to keep the AtomButton here for now, without this if we run "rm -rf .svelte-kit ; yarn dev"
				 When we change this main page design but we will have to check that the page can be opened without issue after running the command above.
				 The bug can be an infinite load on a blank page
			-->
			<div class="flex gap-3 hidden">
				<!-- {#if $currentUser}
					  <AtomRedirect href="/home">
						<AtomButton variant="secondary" text={$_('landing.header.openApp')}>
							<ArrowRight slot="icon" />
						</AtomButton>
					</AtomRedirect> 
				{:else}
					<AtomRedirect href="/login">
						<AtomButton variant="secondary" text={$_('landing.header.loginButton')} />
					</AtomRedirect>
					<AtomRedirect href="/register">
						<AtomButton text={$_('landing.header.signUpButton')} />
					</AtomRedirect>
				{/if} -->

				<AtomButton text={$_('landing.header.signUpButton')} />
			</div>
		</div>
	</div>
</div>
